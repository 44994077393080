.NewRecipe {
  padding-bottom: 15px;
}
  
.NewRecipe textarea {
  height: 300px;
  /* font-size: 16px; */
}

/* #ing {
  width: 29%;
}

#desc {
  width: 69%;
  float: right;
} */

/* .NewRecipe {
  border-width: 2px;
  font-size: 16px;
} */

.NewRecipe .imgchooser {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.NewRecipe .imgchooser + label {
  border-radius: 4px;
  width: 100%;
  padding: 7px 0px;
  text-align: center;
  color: white;
  background-color: #6c757d;
  display: inline-block;
  cursor: pointer;
}

.NewRecipe .imgchooser:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.NewRecipe .imgchooser + label:hover {
  background-color: #5a6268;
}

#resetButton {
  text-align: center;
}

