.footerStyle {
  background-color: #f8f9fa;
  font-size: 14px;
  color: darkslategrey;
  border-top: 1px solid #f8f9fa;
  text-align: right;
  padding-right: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 22px;
  width: 100%;
  z-index: 9999;
}

.linkButton {
  background:none;
  outline-color: transparent;
  border:none;
  padding:0;
  margin-left: 5px;
  margin-right: 5px;
  
  /*optional*/
  font-family:arial,sans-serif; /*input has OS specific font-family*/
  color:lightslategray;
  cursor:pointer;
}

.linkButton:hover {
  color: black;
}

.linkButton:focus {
  outline: none;
  box-shadow: none;
}

.phantomStyle {
  display: block;
  padding: 5px;
  height: 22px;
  width: 100%;
}

/* .Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
}
.Modal {
    position: absolute;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    top                   : 50%;
    left                  : 50%;
    right                 : auto;
    bottom                : auto;
    margin-right           : -50%;
    transform             : translate(-50%, -50%);
    max-width              : 50%
}; */