.App {
  margin-top: 15px;
}

.App .navbar {
  border-radius: 10px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .navbar .search-form {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 35px;
  height: 35px;
}
