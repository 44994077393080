.Home {
  margin-bottom: 50px;
}
.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: lightsteelblue;
}

.Home .recipes hr {
  margin-top: 0px;
  margin-bottom: 15px;
}

.Home .lander p {
  color: #999;
}

.Home .recipes {
  margin-top: 30px;
}

.Home .recipes h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
  color: lightsteelblue;
  font-size: 1.1em;
}

.Home .recipes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: lightsteelblue;
}

.Home .recipes h5 {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
}

.Home .recipes p {
  color: #666;
}

.Home .favorite {
  float: right;
  flex: 0 0 40px;
  position: relative;
}

.react-icons {
  vertical-align: top;
  size: "40px";
}

.list_item_image_container {
  position: relative;
  width: 20%;
  padding-top: 20%;
  float: left;
}

.list_item_image_div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.list_item_image {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.list_item_title {
  float: left;
  clear: top;
  width: calc(80% - 25px);
  padding-left: 20px;
}

.list_item_other {
  background-color: rgb(240, 244, 250);
}

.list_item_title_other {
  float: left;
  clear: top;
  width: 69%;
  padding-left: 20px;
}

.list_item_fav {
  position: right absolute;
  float: right;
  margin-top: 5px;
  width: 23px;
}

.Home .pagination {
  margin-top: 15px;
}

.Home .pagination a {
  cursor: pointer;
  color: lightsteelblue;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}

.Home .pagination .active {
  background-color: #17a2b8;
  color: white;
  border: 1px solid lightsteelblue;
}

.Home .pagination .active a {
  color: white;
}

.Home .pagination .disabled {
  background-color: #dfecf5;
}

/* The main container element */
.Collapsible {
  background-color: white;
  padding-bottom: 10px;
}

/* The content within the collaspable area */
.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid lightsteelblue;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* //The link which when clicked opens the collapsable area */
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid lightsteelblue;
  border-radius: 3px;
  padding: 5px;
  background: white;
  color: lightsteelblue;
}

.Collapsible__trigger:after {
  /* font-family: 'FontAwesome'; */
  content: "|  ^";
  font-weight: 700;
  position: absolute;
  white-space: pre;
  right: 10px;
  top: 5px;
  display: block;
  /* transition: transform 200ms; */
  transform: rotateX(-180deg);
}

/* .Collapsible__trigger.is-open:after {
  content: "|   ^";
  transform: rotateX(0deg);
} */

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.Home .sortToolbar {
  margin-bottom: 2px;
}

.Home .pageSizeToolbar {
  margin-bottom: 2px;
}

.Home .sortButtons {
  margin-left: 10px;
  margin-right: 0;
  z-index: 0;
}

.Home .sortLabel {
  margin-left: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  color: lightsteelblue;
  width: 60px;
}

.Home .pageLabel {
  margin-left: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  color: lightsteelblue;
  width: 60px;
}

.Home .totalHitsLabel {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  color: lightsteelblue;
}
.Home .pageSize {
  margin-left: 10px;
  margin-right: 0;
  width: 213px;
}

@media screen and (min-width: 768px) {
  .Home .sortLabel {
    margin-left: auto;
    margin-right: 0;
  }

  .Home .pageLabel {
    margin-left: auto;
    margin-right: 0;
  }

  .Home .totalHitsLabel {
    margin-left: auto;
    margin-right: 0;
    text-align: right;
  }
}

.Home .changeCatDiv {
  margin-top: 32px;
}

.Home .form-check {
  color: lightsteelblue;
}
