@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }

    .Login form h5 {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      overflow: hidden;
      line-height: 1.5;
      text-overflow: ellipsis;
      color: lightsteelblue;
    }
  }
  