.Recipes {
  white-space: pre-line;
}

.recipe_header {
  display: flex;
}

.recipe_name {
  height: auto;
  float:left;
  flex: 1;
}

.Recipes .favorite {
  float:right;
  flex: 0 0 40px;
  height: auto;
}

.Ingr {
  height: auto;
  width: auto;
  float:left;
}

.recipe_name h6 a{
  color: #17a2b8;
}

.Desc {
  height: auto;
  width: auto;
  clear: both;
}
.Desc a {
  color: #17a2b8;
}
.Img {
  height: auto;
  margin:0px 20px 0px 0;
  float: left;
  width: auto;
  max-width: 40%;
}

.recipe_img {
  width: auto;
  max-width: 100%;
}

.update_recipe .LoaderButton {
  width: calc(50% - 4px);
  float: left;
  margin: 2px;
  margin-bottom: 50px;
}

.remove_recipe .LoaderButton {
  width: calc(50% - 4px);
  float: right;
  margin: 2px;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .Img {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .update_recipe .LoaderButton {
    width: 100%;
    margin: 2px 0;
  }

  .remove_recipe .LoaderButton {
    width: 100%;
    margin: 2px 0;
    margin-bottom: 50px;
  }
}